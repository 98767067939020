/* Custom Fonts */
@font-face {
  font-family: Moderustic;
  src: url("../assets/font/moderustic/Moderustic-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Moderustic;
  src: url("../assets/font/moderustic/Moderustic-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: Moderustic;
  src: url("../assets/font/moderustic/Moderustic-Regular.otf");
  font-weight: 400;
}

/* Default CSS */
* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top : 75px;
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #282c34 !important;
  color:#fff !important;
  font-family: "Moderustic", sans-serif !important;
  font-size: 2w;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  line-height: normal;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem !important;
  }
  .modal-title {
    font-size: 1.2rem !important;
  }
  h5 {
    font-size: 0.9rem !important;
  }
  p, .tagline, a, li{
    font-size: 0.8rem !important;
  }
  .special {
    font-size: 0.9rem !important;
  }
  .image-caption{
    font-size: 0.9rem !important;
  }
  

}

@media (max-width: 768px) {
  h1{
    font-size: 1.6rem !important;
  }
  .modal-title {
    font-size: 1.2rem !important;
  }
  p, .tagline, a, li {
    font-size: 0.85rem !important;
  }

  .special {
    font-size: 0.95rem !important;
  }
  .btn {
    font-size: 0.8rem !important;
  } 
  .banner img {
    display: none;
  }
  .image-caption{
    font-size: 0.9rem !important;
  }
}


@media (max-width: 992px) {
  h1 {
    font-size: 1.7rem !important;
  }
  .modal-title {
    font-size: 1.3rem !important;
  }
  p, .tagline, a, li {
    font-size: 0.9rem !important;
  }
  .special {
    font-size: 1rem !important;
  }
  .image-caption{
    font-size: 0.8rem !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




