/* Modale sombre */
.dark-theme-modal .modal-content {
    background-color: #343a40;  
    color: white;  
  }
  
  .dark-theme-modal .modal-header,
  .dark-theme-modal .modal-footer {
    border-color: white;  
  }
  
  .dark-theme-modal .modal-title {
    color: white; 
  }
  
  .dark-theme-modal .modal-body {
    color: white;  
  }
  
  
  .dark-theme-modal a {
    color: #FFD700;  
    text-decoration: none;  
  }
  
  .dark-theme-modal a:hover {
    color: #FFCC00; 
  }
  
    .dark-theme-modal .modal-header .close {
        color: white !important;  
        font-size: 1.5rem;  
        opacity: 1;  
    }