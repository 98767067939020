.image-credits {
    color: white !important;
    transition: color 0.3s, text-decoration 0.3s;
  }
  
  .image-credits:hover {
    color: rgba(255, 223, 107, 0.884) !important; 
    text-decoration: underline; 
  }

  .dark-theme-modal .modal-header .close {
    color: white;  /* Couleur du bouton de fermeture en blanc */
    font-size: 1.5rem;  /* Ajustez la taille de la croix si nécessaire */
    opacity: 1;  /* Assurez-vous que le bouton est bien visible */
  }
  