.skill {
    padding: 0 0 50px 0;
    position: relative;
  }
  .skill-bx {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
  }
  .skill h2 {
      font-size: 45px;
      font-weight: 700;
  }
  .skill p {
      color: #B8B8B8;
      font-size: 18px;
      letter-spacing: 0.8px;
      line-height: 1.5em;
      margin: 14px 0 75px 0;
  }
  
  
  /* Skills slider */
  .skill-slider {
    width: 100%;
  
  }
  .skill-slider .item img {
    width: 200px !important;
    height: auto !important;
    margin: 0 auto 5px auto !important;
  
  }
  .skill-image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  
  @media (max-width: 1023px) {
    .skill-image img {
      height: 200px; 
      width: auto;
      margin-left: auto; 
      margin-right: auto; 
      display: block; 
      object-fit: cover; 
    }
  }
  
  @media (max-width: 767px) {
    .skill-image img {
      height: 200px;
      width: auto;
      margin-left: auto; 
      margin-right: auto; 
      display: block; 
      object-fit: cover; 
    }
  }

.category-pills {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 5px; 
  padding: 10px;
}
.image-container {
  width: 100%;
  height: 250px; /* Hauteur fixe pour le conteneur */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fixed-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Permet de remplir le conteneur sans distorsion */
}


.btn-pill.selected {
  background-color: white !important;
  color: gray !important;
  border: 2px solid white !important;
}


@media (max-width: 768px) {
  .btn-pill {
    flex: 1 1 100px; 
    min-width: 80px;
    padding: 0.4em 0.8em;
  }
}

@media (max-width: 480px) {
  .btn-pill {
    flex: 1 1 80px;
    min-width: 60px;
    padding: 0.3em 0.6em;
  }

}

.btn-pill {
  flex: 1 1 150px; 
  min-width: 120px; 
  max-width: 200px; 
  text-align: center;
  border: 2px solid white;
  padding: 0.5em 1em;
  transition: background-color 0.3s, color 0.3s;
  border: 2px solid white !important; 
  color: white !important; 
  background-color: transparent !important;
  padding: 0.5em 1.5em !important;
  font-weight: bold !important;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.btn-pill:hover {
  background-color: white !important;
  color: black !important;
  border: 2px solid white !important;
}


.image-caption-container {
  text-align: center;
  padding: 10px; /* Espace autour de chaque image et légende */
}

.image-container {
  width: 100%;
  height: 250px; /* Hauteur fixe pour le conteneur d'image */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fixed-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.caption-container {
  margin-top: 8px; /* Espace entre l'image et la légende */
}

.caption-container h5 {
  font-size: 1rem;
  color:  white;
}

