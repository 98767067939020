.slick-prev,
.slick-next {
  font-size: 20px;
  line-height: 1;
  color: black;
  z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
  color: gray;
}


.slick-dots li button:before {
  color: gray !important; 
}


.slick-dots li.slick-active button:before {
  color: white !important; 
}


.project-category h3 {
  margin-top: 20px;
  font-weight: bold;
}

.project-card {
  margin-bottom: 20px;
}

.project-rectangle {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-rectangle:hover {
  opacity: 0.7;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .project-category h3 {
    font-size: 1.5em;
  }
  
  .project-rectangle span {
    font-size: 1.3em;
  }
}

@media (max-width: 992px) {
  .project-category h3 {
    font-size: 1.3em;
  }
  
  .project-rectangle span {
    font-size: 1em;
  }
}

@media (min-width: 992px) {
  .project-category h3 {
    font-size: 1.5em;
  }
  
  .project-rectangle span {
    font-size: 1.5em;
  }
}

.finished {
  color: rgb(74, 192, 74);
}

.not-finished {
  color: pink;
}
